<template>
	<div></div>
</template>
<script>
import { deleteSnsLoginCookies, getOAuthResultCookie } from '@/utils/cookies.js';
import mixinsAlert from '@/components/common/mixinsAlert';

export default {
	mixins: [mixinsAlert],
	async mounted() {
		const result = getOAuthResultCookie();
		deleteSnsLoginCookies();

		const params = {
			snsType: result.snsTypeLink,
			snsId: result.snsIdLink,
			snsCi: result.snsCiLink,
		};

		try {
			await this.$store.dispatch('newSnsLogin', params);
			this.$toasted.show('계정 연동에 성공했습니다.');
		} catch (e) {
			this.alert('동일 사용자의 계정으로만 연동이 가능합니다');
		}
		await this.$router.push({ name: 'Profile', params: { aTab: 'integration' } });
	},
};
</script>

<style></style>
